import React from 'react'
import Event from '../components/events/Event'

function NewsandEvents() {
  return (
    <>
    <Event/>
    </>
  )
}

export default NewsandEvents