import React from 'react'
import AboutDesc from '../components/about/AboutDesc'
import TeamMembers from '../components/about/TeamMembers'

function About() {
  return (
   <>
   <AboutDesc/>
   <TeamMembers/>
   </>
  )
}

export default About