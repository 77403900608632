import React, { useEffect, useState } from 'react';
import AxiosInstance from '../../Api/AxiosInstance';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

const itemsPerPage = 21; // Define the number of items per page

function Event() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = async () => {
    try {
      const response = await AxiosInstance.get('/event');
      console.log('API Response:', response.data); // Log the API response
      setData(response?.data.event);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Calculate the items to display based on the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = data.slice(startIndex, endIndex);

  // Determine if there are previous and next pages
  const hasPreviousPage = currentPage > 1;
  const hasNextPage = endIndex < data.length;

  const goToPreviousPage = () => {
    if (hasPreviousPage) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (hasNextPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <div className='container mx-auto overflow-hidden px-4 md:px-10 m-10'>
      <h1 className='md:text-3xl sm:text-2xl text-xl font-bold mb-12 text-[#585858] underline-with-space font_poppins ml-4'>
        News & Events
      </h1>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 auto-rows-auto'>
        {currentItems?.map((item) => (
          <div key={item.id} className='flex flex-col p-3  bg-white rounded-lg shadow-2xl border-4'>
            <div className='w-full h-[300px] overflow-hidden'>
              <img
                src={`https://btagglobal.com/admin/uploads/forms/${item.file}`}
                alt={`news${item.id}`}
                className='w-full h-full object-contain'
              />
            </div>
            <div className='mt-4'>
              <h1 className='font-bold text-2xl leading-[30px] font_kufam text-[#3B3A5D]'>{item?.title}</h1>
              <p className='text-base sm:text-md md:text-[11px] text-[#002847] font_source_sans_3 sm:p-0 p-4 leading-4'>
                {item.description}
              </p>
              <p className='mt-2 text-[#B4B7C1]'>{item?.date}</p>
              {item.link && (
                <a
                  href={item.link}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='mt-4 inline-block bg-[#73AB3A] text-white px-4 py-2 rounded'
                >
                  <div className='flex items-center'>
                    Watch Video&nbsp;
                    <PlayCircleOutlineIcon />
                  </div>
                </a>
              )}
              
            </div>
          </div>
        ))}
      </div>

      {/* Pagination Controls */}
      <div className='flex justify-center gap-8 mt-8'>
        <button
          onClick={goToPreviousPage}
          disabled={!hasPreviousPage}
          className={`px-4 py-2 bg-[#73AB3A] font_kufam text-white rounded ${!hasPreviousPage ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          Previous
        </button>
        <button
          onClick={goToNextPage}
          disabled={!hasNextPage}
          className={`px-4 py-2 bg-[#73AB3A] font_kufam text-white rounded ${!hasNextPage ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default Event;
